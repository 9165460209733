(function() {
    'use strict';

    angular.module('ionicSection', [
        'ionicAlert',
        'ionicArticle',
        'ionicData',
        'ionicDataTable',
        'ionicLogin',
        'ionicRequest',
        'ionicVideo',
        'uabDefaultVariable',
        'uabEnvironment'
    ]);
})();
(function() {
    'use strict';

    angular.module('ionicSection').service(
        'SectionCreateService',
        SectionCreateService
    );

    SectionCreateService.$inject = [
        'RequestManagementService',
        'SectionListService'
    ];

    function SectionCreateService(
        RequestManagementService,
        SectionListService
    ) {
        var SectionCreateService = this;

        SectionCreateService.createSection = createSection;
        function createSection(newSection) {
            var options = RequestManagementService.getRequest();

            options = RequestManagementService.setModel(options, 'sections');
            options = RequestManagementService.setAction(options, 'add');

            options = RequestManagementService.setData(options, newSection);

            return ListManager.create(options);
        }

        SectionCreateService.getListManager = getListManager;
        function getListManager() {
            return ListManager;
        }

        SectionCreateService.reset = reset;
        function reset() {

        }

        var ListManager = SectionListService.getListManager();

        SectionListService.reset();

        return SectionCreateService;
    }
})();
(function() {
    'use strict';

    angular.module('ionicSection').service(
        'SectionEditService',
        SectionEditService
    );

    SectionEditService.$inject = [
        'DefaultVariableService',
        'EnvironmentService',
        'RequestManagementService',
        'SectionListService'
    ];

    function SectionEditService(
        DefaultVariableService,
        EnvironmentService,
        RequestManagementService,
        SectionListService
    ) {
        var SectionEditService = this;

        SectionEditService.deleteSection = deleteSection;
        function deleteSection(section) {
            var options = RequestManagementService.getRequest();

            options = RequestManagementService.setModel(options, 'sections');
            options = RequestManagementService.setAction(options, 'delete');

            options = RequestManagementService.setSubAction(options, section.id);

            return ListManager.remove(options);
        }

        SectionEditService.editSection = editSection;
        function editSection(section) {
            var options = RequestManagementService.getRequest();

            options = RequestManagementService.setModel(options, 'sections');
            options = RequestManagementService.setAction(options, 'edit');

            options = RequestManagementService.setSubAction(options, section.id);

            options = RequestManagementService.setData(options, section);

            return ListManager.edit(options);
        }

        SectionEditService.getSection = getSection;
        function getSection(section) {
            if (!DefaultVariableService.isObject(section)) {
                var sectionId = EnvironmentService.get('section_id');
                if (sectionId && sectionId !== 0) {
                    return SectionEditService.loadSection({ id: sectionId });
                } else {
                    return ListManager.getDefault();
                }
            } else {
                return SectionEditService.loadSection(section);
            }
        }

        SectionEditService.getListManager = getListManager;
        function getListManager() {
            return ListManager;
        }

        SectionEditService.loadSection = loadSection;
        function loadSection(section) {
            var options = RequestManagementService.getRequest();

            options = RequestManagementService.setModel(options, 'sections');
            options = RequestManagementService.setAction(options, 'get', section.id);

            options = RequestManagementService.setParams(
                options,
                [
                    'Articles',
                    'Videos'
                ]
            );

            return ListManager.get(options);
        }

        SectionEditService.reset = reset;
        function reset() {

        }

        var ListManager = SectionListService.getListManager();

        SectionEditService.reset();

        return SectionEditService;
    }
})();

(function() {
    'use strict';

    angular.module('ionicSection').service(
        'SectionListService',
        SectionListService
    );

    SectionListService.$inject = [
        'DefaultVariableService',
        'ListManagementService',
        'RequestManagementService'
    ];

    function SectionListService(
        DefaultVariableService,
        ListManagementService,
        RequestManagementService
    ) {
        var SectionListService = this;

        SectionListService.getListManager = getListManager;
        function getListManager() {
            return ListManager;
        }

        SectionListService.buildRequest = buildRequest;
        function buildRequest(options) {
            options = RequestManagementService.getRequest(options);

            options = RequestManagementService.setModel(options, 'sections');
            options = RequestManagementService.setAction(options, 'get');

            options = RequestManagementService.setOrder(options, '+title');

            return options;
        }

        SectionListService.getMore = getMore;
        function getMore(options) {
            options = SectionListService.buildRequest(options);

            return ListManager.getPage(options);
        }

        SectionListService.getPage = getPage;
        function getPage(options) {
            options = SectionListService.buildRequest(options);

            return ListManager.getPage(options);
        }

        SectionListService.reset = reset;
        function reset() {
            ListManager.reset();
        }

        var ListManager = ListManagementService.getListManager(
            SectionListService.getMore
        );

        SectionListService.reset();

        return SectionListService;
    }
})();
(function() {
    'use strict';

    angular.module('ionicSection').controller(
        'SectionCreateController',
        SectionCreateController
    );

    SectionCreateController.$inject = [
        'AlertService',
        '$scope',
        'DefaultVariableService',
        'LoginService',
        'SectionCreateService'
    ];

    function SectionCreateController(
        AlertService,
        $scope,
        DefaultVariableService,
        LoginService,
        SectionCreateService
    ) {
        var SectionCreateController = this;

        $scope.$watch(
            'onCancel',
            function(onCancel) {
                SectionCreateController.onCancel = DefaultVariableService.getPromise(
                    onCancel
                );
            }.bind(SectionCreateController)
        );

        $scope.$watch(
            'onCreate',
            function(onCreate) {
                SectionCreateController.onCreate = DefaultVariableService.getPromise(
                    onCreate
                );
            }.bind(SectionCreateController)
        );

        SectionCreateController.cancel = cancel;
        function cancel() {
            SectionCreateController.onCancel();
        }

        SectionCreateController.createSection = createSection;
        function createSection(newSection) {
            newSection = DefaultVariableService.get(
                newSection,
                SectionCreateController.newSection
            );

            SectionCreateController.isCreatingSection = true;

            return SectionCreateService.createSection(newSection).then(
                function(newSection) {
                    if (newSection) {
                        SectionCreateController.resetNewSection();

                        AlertService.addMessage('SECTION CREATED.');

                        SectionCreateController.onCreate(newSection);
                    }

                    return newSection;
                }
            ).finally(
                function() {
                    SectionCreateController.isCreatingSection = false;
                }
            );
        }

        SectionCreateController.selectArticle = selectArticle;
        function selectArticle(article) {
            article = DefaultVariableService.getObject(article);

            SectionCreateController.newSection.article = article;
            SectionCreateController.newSection.article_id = article.id;

            return SectionCreateController.newSection.article_id;
        }

        SectionCreateController.selectVideo = selectVideo;
        function selectVideo(video) {
            video = DefaultVariableService.getObject(video);

            SectionCreateController.newSection.video = video;
            SectionCreateController.newSection.video_id = video.id;

            return SectionCreateController.newSection.video_id;
        }

        SectionCreateController.toggleCreator = toggleCreator;
        function toggleCreator() {
            SectionCreateController.showCreator = !SectionCreateController.showCreator;
        }

        SectionCreateController.reset = reset;
        function reset() {
            SectionCreateController.isCreatingSection = false;

            SectionCreateController.resetNewSection();

            SectionCreateController.showCreator = false;
        }

        SectionCreateController.resetNewSection = resetNewSection;
        function resetNewSection() {
            SectionCreateController.newSection = {};
            SectionCreateController.newSection.article = {};
            SectionCreateController.newSection.video = {};
        }

        SectionCreateController.init = init;
        function init() {
            SectionCreateController.reset();
        }

        LoginService.register(SectionCreateController);
    }
})();
(function() {
    'use strict';

    angular.module('ionicSection').directive(
        'ionicSectionCreate',
        ionicSectionCreate
    );

    function ionicSectionCreate() {
        return {
            controller:   'SectionCreateController',
            controllerAs: 'ctrl',
            restrict:     'E',
            scope: {
                onCancel: '=',
                onCreate: '='
            },
            template:'<div layout="column" layout-padding class="md-whiteframe-z1"><div layout="row"><label class="item item-input item-floating-label col"><span class="input-label">TITLE</span> <input type="text" placeholder="TITLE" data-ng-model="ctrl.newSection.title"></label></div><label class="item item-input item-stacked-label"><span class="input-label">ARTICLE</span><ionic-article-filter on-select="ctrl.selectArticle" target="ctrl.newSection.article"></ionic-article-filter></label> <label class="item item-input item-stacked-label"><span class="input-label">VIDEO</span><ionic-video-filter on-select="ctrl.selectVideo" target="ctrl.newSection.video"></ionic-video-filter></label><div class="row"><button class="button button-full accept-button col-xl-6" data-ng-click="ctrl.createSection()" data-ng-disabled="ctrl.isCreatingSection">{{ ctrl.isCreatingSection ? \'CREATING...\' : \'CREATE\' }}</button> <button class="button button-full decline-button col-xl-6" data-ng-click="ctrl.cancel()" data-ng-disabled="ctrl.isCreatingSection">CANCEL</button></div></div>'
        };
    }
})();
(function() {
    'use strict';

    angular.module('ionicSection').controller(
        'SectionEditController',
        SectionEditController
    );

    SectionEditController.$inject = [
        'AlertService',
        'DefaultVariableService',
        'LoginService',
        'SectionEditService',
        '$scope'
    ];

    function SectionEditController(
        AlertService,
        DefaultVariableService,
        LoginService,
        SectionEditService,
        $scope
    ) {
        var SectionEditController = this;

        $scope.$watch(
            'onCancel',
            function(onCancel) {
                SectionEditController.onCancel = DefaultVariableService.getPromise(
                    onCancel
                );
            }.bind(SectionEditController)
        );

        $scope.$watch(
            'onDelete',
            function(onDelete) {
                SectionEditController.onDelete = DefaultVariableService.getPromise(
                    onDelete
                );
            }.bind(SectionEditController)
        );

        $scope.$watch(
            'onEdit',
            function(onEdit) {
                SectionEditController.onEdit = DefaultVariableService.getPromise(
                    onEdit
                );
            }.bind(SectionEditController)
        );

        $scope.$watch(
            'section',
            function(section) {
                SectionEditController.loadSection(section);
            }.bind(SectionEditController)
        );

        SectionEditController.cancel = cancel;
        function cancel() {
            SectionEditController.onCancel();
        }

        SectionEditController.clear = clear;
        function clear() {
            SectionEditService.reset();
            SectionEditController.reset();
        }

        SectionEditController.deleteSection = deleteSection;
        function deleteSection(section) {
            SectionEditController.isDeletingSection = true;

            section = DefaultVariableService.get(
                section,
                SectionEditController.section
            );

            return SectionEditService.deleteSection(section).then(
                function(data) {
                    if (data) {
                        AlertService.addMessage('SECTION DELETED');
                        
                        SectionEditController.onDelete(section);
                    }

                    return data;
                }
            ).finally(
                function() {
                    SectionEditController.isDeletingSection = false;
                }
            );
        }

        SectionEditController.editSection = editSection;
        function editSection(section) {
            SectionEditController.isEditingSection = true;

            section = DefaultVariableService.get(
                section,
                SectionEditController.section
            );

            delete section.article;
            delete section.video;

            return SectionEditService.editSection(section).then(
                function(data) {
                    if (data) {
                        AlertService.addMessage('SECTION EDITED');
                        
                        SectionEditController.onEdit(section);
                    }

                    return data;
                }
            ).finally(
                function() {
                    SectionEditController.isEditingSection = false;
                }
            );
        }

        SectionEditController.loadSection = loadSection;
        function loadSection(section) {
            section = DefaultVariableService.get(
                section,
                SectionEditController.section
            );

            SectionEditController.isLoadingSection = true;

            return SectionEditService.getSection(section).then(
                function(data) {
                    if (data) {
                        SectionEditController.section = DefaultVariableService.getObject(
                            data.sections
                        );
                    }

                    return data;
                }
            ).finally(
                function() {
                    SectionEditController.isLoadingSection = false;
                }
            );
        }

        SectionEditController.selectArticle = selectArticle;
        function selectArticle(article) {
            article = DefaultVariableService.getObject(article);

            SectionEditController.section.article = article;
            SectionEditController.section.article_id = article.id;

            return SectionEditController.section.article_id;
        }

        SectionEditController.selectVideo = selectVideo;
        function selectVideo(video) {
            video = DefaultVariableService.getObject(video);

            SectionEditController.section.video = video;
            SectionEditController.section.video_id = video.id;

            return SectionEditController.section.video_id;
        }

        SectionEditController.reset = reset;
        function reset() {
            SectionEditController.articles = [];
            
            SectionEditController.isDeletingSection = false;

            SectionEditController.isEditingSection = false;

            SectionEditController.isLoadingSection = true;

            SectionEditController.showCreator = false;

            SectionEditController.section = DefaultVariableService.getObject();

            SectionEditController.section.targetArticles = {};

            SectionEditController.section.targetVideos = {};

            SectionEditController.videos = [];
        }

        SectionEditController.init = init;
        function init() {
            SectionEditController.reset();
        }

        LoginService.register(SectionEditController);
    }
})();
(function() {
    'use strict';

    angular.module('ionicSection').directive(
        'ionicSectionEdit',
        ionicSectionEdit
    );

    function ionicSectionEdit() {
        return {
            controller:   'SectionEditController',
            controllerAs: 'ctrl',
            restrict:     'E',
            scope: {
                onCancel: '=',
                onDelete: '=',
                onEdit:   '=',
                section:  '='
            },
            template:'<div><label class="item item-input item-floating-label"><span class="input-label">TITLE</span> <input type="text" placeholder="TITLE" data-ng-model="ctrl.section.title"></label> <label class="item item-input item-stacked-label"><span class="input-label">ARTICLE</span><ionic-article-filter on-select="ctrl.selectArticle" target="ctrl.section"></ionic-article-filter></label> <label class="item item-input item-stacked-label"><span class="input-label">VIDEO</span><ionic-video-filter on-select="ctrl.selectVideo" target="ctrl.section"></ionic-video-filter></label><div class="row"><button class="button button-full accept-button col-xl-4" data-ng-click="ctrl.editSection()" data-ng-disabled="ctrl.isEditingSection || ctrl.isDeletingSection">{{ ctrl.isEditingSection ? \'EDITING...\' : \'EDIT\' }}</button> <button class="button button-full decline-button col-xl-4" data-ng-click="ctrl.deleteSection()" data-ng-disabled="ctrl.isEditingSection || ctrl.isDeletingSection">{{ ctrl.isDeletingSection ? \'DELETING...\' : \'DELETE\' }}</button> <button class="button button-full col-xl-4" data-ng-click="ctrl.cancel()" data-ng-disabled="ctrl.isEditingSection || ctrl.isDeletingSection">CANCEL</button></div></div>'
        };
    }
})();
(function() {
    'use strict';

    angular.module('ionicSection').controller(
        'SectionListController',
        SectionListController
    );

    SectionListController.$inject = [
        'SectionListService',
        'DefaultVariableService',
        'LoginService'
    ];

    function SectionListController(
        SectionListService,
        DefaultVariableService,
        LoginService
    ) {
        var SectionListController = this;

        SectionListController.clear = clear;
        function clear() {
            SectionListService.reset();
            SectionListController.reset();
        }

        SectionListController.forceRefresh = forceRefresh;
        function forceRefresh() {
            SectionListController.clear();
            SectionListController.init();
        }

        SectionListController.loadSections = loadSections;
        function loadSections(options) {
            SectionListController.isLoadingSections = true;

            return SectionListService.getMore(options).then(
                function(data) {
                    var sections = DefaultVariableService.getArray(
                        data.sections
                    );

                    if (sections) {
                        SectionListController.sections = sections;

                        SectionListController.canLoadMore = false;
                    }

                    return data;
                }
            ).finally(
                function() {
                    SectionListController.isLoadingSections = false;
                }
            );
        }

        SectionListController.reset = reset;
        function reset() {
            SectionListController.canLoadMore = true;

            SectionListController.isLoadingSections = false;

            SectionListController.resetArticles();
        }

        SectionListController.resetArticles = resetArticles;
        function resetArticles() {
            SectionListController.sections = [];
        }

        SectionListController.init = init;
        function init() {
            SectionListController.reset();
            SectionListController.loadSections();
        }

        LoginService.register(SectionListController);
    }
})();
(function() {
    'use strict';

    angular.module('ionicSection').directive('ionicSectionList', ionicSectionList);

    function ionicSectionList() {
        return {
            controller:   'SectionListController',
            controllerAs: 'ctrl',
            restrict:     'E',
            scope:        {},
            template:'<ul><li data-ng-repeat="section in ctrl.sections">{{ section.title }}</li><li data-ng-if="ctrl.isLoadingSections">Loading...</li><li data-ng-if="ctrl.sections.length === 0 && !ctrl.isLoadingSections">No sections.</li><li data-ng-if="!ctrl.canLoadMore">No more sections.</li></ul><div><input type="button" value="More" data-ng-click="ctrl.loadMore()" data-ng-disabled="ctrl.isLoadingSections || !ctrl.canLoadMore"> <input type="button" value="Page" data-ng-click="ctrl.loadPage()" data-ng-disabled="ctrl.isLoadingSections || !ctrl.canLoadMore"> <input type="button" value="Refresh" data-ng-click="ctrl.forceRefresh()"></div>'
        };
    }
})();
(function() {
    'use strict';

    angular.module('ionicSection').controller(
        'SectionManagementController',
        SectionManagementController
    );

    SectionManagementController.$inject = [
        'DefaultVariableService',
        'LoginService',
        'SectionListService'
    ];

    function SectionManagementController(
        DefaultVariableService,
        LoginService,
        SectionListService
    ) {
        var SectionManagementController = this;

        SectionManagementController.clear = clear;
        function clear() {
            SectionManagementController.reset();
            SectionListService.reset();
        }

        SectionManagementController.loadSections = loadSections;
        function loadSections(options) {
            SectionManagementController.isLoadingSections = true;

            return SectionListService.getPage(
                options
            ).then(
                function(data) {
                    if (data) {
                        SectionManagementController.sections = DefaultVariableService.getArray(
                            data.sections
                        );

                        SectionManagementController.count = DefaultVariableService.getInteger(
                            data.count,
                            0
                        );
                    }

                    return data;
                }
            ).finally(
                function() {
                    SectionManagementController.isLoadingSections = false;
                }
            );
        }

        SectionManagementController.onCreate = onCreate;
        function onCreate() {
            SectionManagementController.showCreator = false;

            SectionManagementController.loadSections();
        }

        SectionManagementController.onDelete = onDelete;
        function onDelete() {
            SectionManagementController.resetSection();

            SectionManagementController.loadSections();
        }

        SectionManagementController.onEdit = onEdit;
        function onEdit() {
            SectionManagementController.resetSection();

            SectionManagementController.loadSections();
        }

        SectionManagementController.selectSection = selectSection;
        function selectSection(section) {
            SectionManagementController.section = DefaultVariableService.getObject(
                section
            );
        }

        SectionManagementController.toggleCreator = toggleCreator;
        function toggleCreator() {
            SectionManagementController.showCreator = !SectionManagementController.showCreator;
        }

        SectionManagementController.reset = reset;
        function reset() {
            SectionManagementController.columns = [
                {
                    position: 1,
                    title:    'TITLE',
                    key:      'title'
                },
                {
                    position: 2,
                    title:    'ARTICLE ID',
                    key:      'article_id'
                },
                {
                    position: 3,
                    title:    'VIDEO ID',
                    key:      'video_id'
                },
                {
                    position: 4,
                    title:    'ID',
                    key:      'id'
                }
            ];

            SectionManagementController.isLoadingSections = false;

            SectionManagementController.showCreator = false;
            
            SectionManagementController.resetSection();

            SectionManagementController.sections = [];
        }

        SectionManagementController.resetSection = resetSection;
        function resetSection() {
            SectionManagementController.section = DefaultVariableService.getObject();
        }

        SectionManagementController.init = init;
        function init() {
            SectionManagementController.reset();
            SectionManagementController.loadSections();
        }

        LoginService.register(SectionManagementController);
    }
})();
(function() {
    'use strict';

    angular.module('ionicSection').directive(
        'ionicSectionManagement',
        ionicSectionManagement
    );

    function ionicSectionManagement() {
        return {
            controller:   'SectionManagementController',
            controllerAs: 'ctrl',
            restrict:     'E',
            scope:         {},
            template:'<div data-ng-if="ctrl.section.id === 0 && !ctrl.showCreator"><button class="button button-full accept-button" data-ng-click="ctrl.toggleCreator()">CREATE NEW SECTION</button><ionic-data-table columns="ctrl.columns" objects="ctrl.sections" on-request="ctrl.loadSections" on-select="ctrl.selectSection" total="ctrl.count"></ionic-data-table></div><ionic-section-edit data-ng-if="ctrl.section.id !== 0" on-edit="ctrl.onEdit" on-cancel="ctrl.resetSection" on-delete="ctrl.onDelete" section="ctrl.section"></ionic-section-edit><ionic-section-create data-ng-if="ctrl.showCreator" on-cancel="ctrl.toggleCreator" on-create="ctrl.onCreate"></ionic-section-create>'
        };
    }
})();